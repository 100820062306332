import React from 'react';

const RSVPForm = () => {


  return (
    <div className='flex justify-center mt- mb-10'>
        <button className='border rounded-xl p-3 font-bold transition duration-500 ease-out-out transform hover:scale-110 bg-blue-200'>
  <a href="https://docs.google.com/forms/d/e/1FAIpQLSdip4GOpRpavnANAvFZCDptXmgQte2skT8uCHgSzOSFZskoZw/viewform?usp=sf_link" target='_blank' rel='noreferrer'>Fyll ut skjema</a>
</button>

    </div>
  );
};

export default RSVPForm;
