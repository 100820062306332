import React from 'react'

import Alexognina from '../images/alexognina.png';

const Velkommen = () => {
  return (
    <div className='flex flex-col justify-center items-center' >
      <h1 className='mt-10 text-7xl'>Bryllup</h1>
      <h2 className='mt-5 text-5xl'>Nina & Alexander </h2>
      <h3 className='mt-8 text-4xl'>29.06.2024</h3>
      <p className='text-center mt-5'>Her vil du finne informasjon om bryllupet. <br /> Fyll ut skjemaet nederst på siden!</p>
      <img src={Alexognina} alt="img" className='mt-3 h-96' />
      <div className='mt-10 bg-no-repeat'>
        
      </div> 
    </div>
  )
}

export default Velkommen