
import './App.css';
import Bryllup from './components/Bryllup';
import RSVPForm from './components/RSVPFrom';
import Velkommen from './components/Velkommen';



function App() {
  return (
    <>
    <Velkommen />
    <Bryllup />
    <RSVPForm />
    </>
      
    
  );
}

export default App;
