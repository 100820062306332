import React from 'react'

import Blomster from '../images/blomster.png'
const Bryllup = () => {
  return (
    <div className='flex justify-center items-center '>
      <img src={Blomster} className='w-56 h-56 transform scale-x-[-1]' alt="" />
    <div className='w-96'> 
      <p className='text-center'>Vi gleder oss til å se dere i bryllupet, mer informasjon kommer!</p>
    </div>
    <img src={Blomster} className='w-56 h-56 hidden sm:inline-block' alt="" />
    </div>
  )
}

export default Bryllup